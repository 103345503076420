import React from 'react'
import Pricing from '../../components/Pricing/Pricing'
import PageSeo from '../../components/SEO'

function index() {
    return (
        <>
            <PageSeo pageTitle="Pricing" />
            <Pricing/>
        </>
    )
}

export default index
