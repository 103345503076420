import React from "react"
import "../../../styles/pricing/PricingSection.css"
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md"
import { useState } from "react"
import { openPopupWidget } from "react-calendly"
import InfluencerFooter from "../../LandingSubComponents/InfluencerFooter/InfluencerFooter"
import Faq from "react-faq-component"
import pricingFaq from '../../../../static/assets/pricingfaqimg.png'
import useSiteMetadata from "../../SiteMetaData";
function PricingFAQSection() {
  const {calendlyDemoUrl} = useSiteMetadata()

  const bookDemo = () => openPopupWidget({ url: calendlyDemoUrl })

  const styles = {
    bgColor: "transparent",
    titleTextColor: "white",
    rowTitleColor: "white",
    rowContentColor: "white",
    arrowColor: "white",
    padding: "10px",
  }

  const data = {
    rows: [
      {
        title: "How can I pay?",
        content: `If you are on Shopify, we integrate with Shopify Billing APIs directly. Else, we use stripe and you can pay by any card`,
      },
      {
        title: "Which Plan is the best for me?",
        content: `Depends on how much traffic you have on the website. If you have less traffic on your website, you can start with our Lite plan. We recommend Basic plan as that's good enough to start. You can book a meet with us and we will be happy to see your requirements and suggest you the best plan for you. Let's talk and we will share how other companies are using this too.`,
      },
      {
        title: "How can I cancel subscription?",
        content: `Just go to your influencerbit dashboard and you can cancel the subscription yourself anytime. Or you can just send us the message via in app chat support or mail us on support@influencerbit.com`,
      },
      {
        title: "How is pricing calculated?",
        content: `Depends on the features needed, integrations, type of social trackings, daily orders, total profiles tracked by our system, barter automation requirements, a dedicated manager to setup flows requirement, etc.`,
      },

    ],
  }

  return (
    <div>
      <div className="container is-fluid pricingFAQContainer">
        <div className="columns is-multiline is-mobile">
          <div className="column is-12">
            <h3 className="is-size-3-desktop is-size-3-tablet is-size-3-mobile has-text-black faqHeader">
              Frequently Asked Questions
            </h3>
            <p className="has-text-black is-size-5-desktop is-size-6-mobile is-size-4-tablet mt-3  faqSubHeader">
              Have a doubt? Check if we have answered it below!
            </p>
          </div>

          <div className="column is-12">
            <Faq data={data} styles={styles} />
          </div>
        </div>
        <div className="columns is-multiline is-mobile mt-6">
          <div className="column is-12 has-text-centered pricingFaqImgContainer">
            <img
              src={pricingFaq}
              alt="pricingfaqimg"
              title="pricingfaqimg"

              className="image pricingFaqImg"
              loading="lazy"
            />
          </div>
          <div className="column is-12 has-text-centered">
            <div className="pricingFAQBtnContainer mt-6 has-text-centered-mobile">
              <a href="/pricing" className="pricingDoubtsText is-size-4-desktop">
                Confused in Plans?
              </a>
              <button
                className="button is-medium bookDemoBtn pricingDemoBtn ml-5  is-size-6"
                onClick={() => bookDemo()}
              >
                BOOK A CALL WITH US
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="pricingFaqFooter">
        <InfluencerFooter />
      </div>
    </div>
  )
}

export default PricingFAQSection
