import React from "react"

import "../../../styles/pricing/PricingSection.css"
import { openPopupWidget } from "react-calendly"
import PricingNavbar from "../PricingNavbar/PricingNavbar"
import pricingLanding from "../../../../static/assets/pricingLanding.png"
import checkboxWhite from "../../../../static/assets/checkbox_white.png"
import crossWhite from "../../../../static/assets/cross_white.png"
import useSiteMetadata from "../../SiteMetaData";
import PricingData from "../PricingPlans";

function PricingIntroSection() {
  const {calendlyDemoUrl, dashboardRegisterUrl} = useSiteMetadata()

  const bookDemo = () => {
    openPopupWidget({ url: calendlyDemoUrl })
  }

  const signUp = () => {
    window.open(dashboardRegisterUrl, "_blank")
  }

  return (
    <div className="pricingIntroSectionContainer">
      <PricingNavbar />
      <div className="container is-fluid pricingIntroContainer">
        <div className="columns is-multiline is-mobile">
          <div className="column is-5-desktop is-6-tablet is-12-mobile">
            <h2 className="has-text-black pricingIntroHeader">
             Speak with us & we'll custom make your desired plan!
            </h2>

            <div className="pricingIntroBtnContainer mt-6 has-text-centered-mobile">
              {/*<p className="pricingDoubtsText is-size-4-desktop">*/}
              {/*  Have Doubts?<br />*/}

              {/*</p>*/}

              <button
                className="button is-medium bookDemoBtn pricingDemoBtn ml-5  is-size-6"
                onClick={() => bookDemo()}
              >
                BOOK A MEET WITH US
              </button>

            </div>
          </div>
          <div className="column is-7-desktop is-6-tablet is-12-mobile pricingLandingImgCol">
            <img
              src={pricingLanding}
              alt="pricingLanding"
              title="pricingLanding"
              className="image"
              loading="lazy"
            />
          </div>
        </div>
      </div>
{/*      <div className="container is-fluid pricingIntroCardContainer">*/}
{/*        <div className="columns  is-multiline is-mobile is-hidden-touch is-justify-content-space-between">*/}
{/*          {PricingData.plans.map((pri, key) => {*/}
{/*            return (*/}
{/*              <>*/}
{/*                <div*/}
{/*                  className={`column is-2-desktop is-4-tablet mt-5 is-12-mobile`}*/}
{/*                  key={key}*/}
{/*                >*/}
{/*                  <div*/}
{/*                    className={`${*/}
{/*                      pri.col ? "pricingIntroCard" : "pricingIntroCard1"*/}
{/*                    }`}*/}
{/*                  >*/}
{/*                    <h4*/}
{/*                      className={`has-text-centered ${false && "pricingIntroCardPrice"} pricingIntroCardHeader is-size-3-desktop is-size-4-mobile is-size-4-tablet`}*/}
{/*                    >*/}
{/*                      {pri.planName}*/}
{/*                    </h4>*/}
{/*                    <p*/}
{/*                      className={`is-size-3-desktop pricingIntroCardPrice has-text-centered mt-3 ${*/}
{/*                        pri.visible*/}
{/*                          ? ""*/}
{/*                          : "is-unselectable is-invisible is-hidden-mobile"*/}
{/*                      } `}*/}
{/*                    >*/}
{/*                      ${pri.planPrice}/mo*/}
{/*                    </p>*/}

{/*                    <p*/}
{/*                      className={`${*/}
{/*                        pri.col*/}
{/*                          ? "pricingIntroCardValues"*/}
{/*                          : "pricingIntroCardValues1"*/}
{/*                      } has-text-centered  is-size-5-desktop is-size-5-mobile is-size-5-tablet`}*/}
{/*                    >*/}
{/*                      {pri.trackedProfiles}*/}
{/*/!*                      {pri.hideMobile ? (PricingData.fieldsDescription["trackedProfiles"] ? (*/}
{/*                          <p*/}
{/*                              title={PricingData.fieldsDescription["trackedProfiles"]}*/}
{/*                              placement={"top"}*/}
{/*                          >*/}
{/*                            <i className="fa fa-info-circle" aria-hidden="true" />*/}
{/*                          </p>*/}
{/*                      ) : null) : null}*!/*/}
{/*                    </p>*/}
{/*                    <p*/}
{/*                      className={`${*/}
{/*                        pri.col*/}
{/*                          ? "pricingIntroCardValues"*/}
{/*                          : "pricingIntroCardValues1"*/}
{/*                      } has-text-centered  is-size-5-desktop is-size-5-mobile is-size-5-tablet`}*/}
{/*                    >*/}
{/*                      {pri.businessAccounts}*/}
{/*                      {pri.hideMobile ? (PricingData.fieldsDescription["businessAccounts"] ?? null) : null}*/}
{/*                    </p>*/}
{/*                    <p*/}
{/*                      className={`${*/}
{/*                        pri.col*/}
{/*                          ? "pricingIntroCardValues"*/}
{/*                          : "pricingIntroCardValues1"*/}
{/*                      } has-text-centered  is-size-5-desktop is-size-5-mobile is-size-5-tablet`}*/}
{/*                    >*/}
{/*                      {pri.profileRefreshes}*/}
{/*                    </p>*/}
{/*                    <p*/}
{/*                      className={`${*/}
{/*                        pri.col*/}
{/*                          ? "pricingIntroCardValues is-capitalized"*/}
{/*                          : "pricingIntroCardValues1 "*/}
{/*                      } has-text-centered  is-size-5-desktop is-size-5-mobile is-size-5-tablet`}*/}
{/*                    >*/}
{/*                      {pri.standardIntegrations}*/}
{/*                    </p>*/}
{/*                    <p*/}
{/*                        className={`${*/}
{/*                            pri.col*/}
{/*                                ? "pricingIntroCardValues is-capitalized"*/}
{/*                                : "pricingIntroCardValues1 "*/}
{/*                        } has-text-centered  is-size-5-desktop is-size-5-mobile is-size-5-tablet`}*/}
{/*                    >*/}
{/*                      {pri.reels}*/}
{/*                    </p>*/}
{/*                    <p*/}
{/*                        className={`${*/}
{/*                            pri.col*/}
{/*                                ? "pricingIntroCardValues is-capitalized"*/}
{/*                                : "pricingIntroCardValues1 "*/}
{/*                        } has-text-centered  is-size-5-desktop is-size-5-mobile is-size-5-tablet`}*/}
{/*                    >*/}
{/*                      {pri.storyDetection}*/}
{/*                    </p>*/}
{/*                    <p*/}
{/*                        className={`${*/}
{/*                            pri.col*/}
{/*                                ? "pricingIntroCardValues is-capitalized"*/}
{/*                                : "pricingIntroCardValues1 "*/}
{/*                        } has-text-centered  is-size-5-desktop is-size-5-mobile is-size-5-tablet`}*/}
{/*                    >*/}
{/*                      {pri.brandsInstagramAnalysis}*/}
{/*                    </p>*/}
{/*                    <p*/}
{/*                        className={`${*/}
{/*                            pri.col*/}
{/*                                ? "pricingIntroCardValues is-capitalized"*/}
{/*                                : "pricingIntroCardValues1 "*/}
{/*                        } has-text-centered  is-size-5-desktop is-size-5-mobile is-size-5-tablet`}*/}
{/*                    >*/}
{/*                      {pri.customIntegrations}*/}
{/*                    </p>*/}
{/*                    <p*/}
{/*                        className={`${*/}
{/*                            pri.col*/}
{/*                                ? "pricingIntroCardValues is-capitalized"*/}
{/*                                : "pricingIntroCardValues1 "*/}
{/*                        } has-text-centered  is-size-5-desktop is-size-5-mobile is-size-5-tablet`}*/}
{/*                    >*/}
{/*                      {pri.successManager}*/}
{/*                    </p>*/}
{/*                    <div*/}
{/*                      className={`has-text-centered ${*/}
{/*                        pri.col*/}
{/*                          ? "mt-6"*/}
{/*                          : "mt-6 is-invisible is-unselectable is-hidden-mobile"*/}
{/*                      }`}*/}
{/*                    >*/}
{/*                      <button*/}
{/*                        className={`button is-medium  ${*/}
{/*                          false ? "pricingActive" : "pricingGetStartedBtn"*/}
{/*                        } is-size-6`}*/}
{/*                        onClick={signUp}*/}
{/*                      >*/}
{/*                        {false ? "CURRENTLY ACTIVE" : "GET STARTED"}*/}
{/*                      </button>*/}
{/*                    </div>*/}
{/*                  </div>*/}
{/*                </div>*/}
{/*              </>*/}
{/*            )*/}
{/*          })}*/}
{/*        </div>*/}
{/*        <div className="columns is-mobile is-multiline is-hidden-desktop">*/}
{/*          {PricingData.plans.filter(i => !i.hideMobile).map((pri, key) => {*/}
{/*            return (*/}
{/*              <div*/}
{/*                className="column is-12-mobile is-9-tablet is-offset-1-tablet"*/}
{/*                key={key}*/}
{/*              >*/}
{/*                <div className="pricingMobileCard">*/}
{/*                  <div className="paddingCard">*/}
{/*                    <div className="pricingMobileHeaderBox">*/}
{/*                      <h4 className="pricingMobileCardHeader has-text-white is-size-4">*/}
{/*                        {pri.planName}*/}
{/*                      </h4>*/}
{/*                      {key !== 1000 && (*/}
{/*                        <p className="pricingMobilePrice is-pulled-right is-size-4">*/}
{/*                          ${pri.planPrice}/mo*/}
{/*                        </p>*/}
{/*                      )}*/}
{/*                    </div>*/}

{/*                    <div className="pricingMobileInfoBox has-text-white is-uppercase">*/}
{/*                      <p className="pricingMobileInfo">TRACKED PROFILES</p>*/}
{/*                      <p className="pricingMobileInfoCount is-pulled-right">*/}
{/*                        {pri.trackedProfiles}*/}
{/*                      </p>*/}
{/*                    </div>*/}
{/*                    <div className="pricingMobileInfoBox has-text-white is-uppercase">*/}
{/*                      <p className="pricingMobileInfo">*/}
{/*                        INSTAGRAM BUSINESS ACCOUNTS*/}
{/*                      </p>*/}
{/*                      <p className="pricingMobileInfoCount is-pulled-right">*/}
{/*                        {pri.businessAccounts}*/}
{/*                      </p>*/}
{/*                    </div>*/}
{/*                    <div className="pricingMobileInfoBox has-text-white is-uppercase">*/}
{/*                      <p className="pricingMobileInfo">*/}
{/*                        DAILY PROFILE REFRESHES*/}
{/*                      </p>*/}
{/*                      <p className="pricingMobileInfoCount is-pulled-right">*/}
{/*                        {pri.profileRefreshes}*/}
{/*                      </p>*/}
{/*                    </div>*/}
{/*                    <div className="pricingMobileInfoBoxLast has-text-white is-uppercase">*/}
{/*                      <p className="pricingMobileInfo">STANDARD INTEGRATIONS</p>*/}
{/*                      <p className="pricingMobileInfoCount is-pulled-right">*/}
{/*                        {pri.standardIntegrations}*/}
{/*                      </p>*/}
{/*                    </div>*/}
{/*                    <div className="pricingMobileInfoBoxLast has-text-white is-uppercase">*/}
{/*                      <p className="pricingMobileInfo">REELS TRACKING</p>*/}
{/*                      <p className="pricingMobileInfoCount is-pulled-right">*/}
{/*                        {pri.reels}*/}
{/*                      </p>*/}
{/*                    </div>*/}
{/*                    <div className="pricingMobileInfoBoxLast has-text-white is-uppercase">*/}
{/*                      <p className="pricingMobileInfo">STORY TRACKING</p>*/}
{/*                      <p className="pricingMobileInfoCount is-pulled-right">*/}
{/*                        {pri.storyDetection}*/}
{/*                      </p>*/}
{/*                    </div>*/}
{/*                    <div className="pricingMobileInfoBoxLast has-text-white is-uppercase">*/}
{/*                      <p className="pricingMobileInfo">CUSTOM DEV</p>*/}
{/*                      <p className="pricingMobileInfoCount is-pulled-right">*/}
{/*                        {pri.customIntegrations}*/}
{/*                      </p>*/}
{/*                    </div>*/}
{/*                    <div className="pricingMobileInfoBoxLast has-text-white is-uppercase">*/}
{/*                      <p className="pricingMobileInfo">SUCCESS MANAGER</p>*/}
{/*                      <p className="pricingMobileInfoCount is-pulled-right">*/}
{/*                        {pri.successManager}*/}
{/*                      </p>*/}
{/*                    </div>*/}
{/*                  </div>*/}

{/*                  <div className="profileBtnContainer has-text-centered">*/}
{/*                      <button*/}
{/*                        className="button is-medium has-text-white pricingMobileGetStartedBtn  is-size-6"*/}
{/*                        onClick={signUp}*/}
{/*                      >*/}
{/*                        GET STARTED*/}
{/*                      </button>*/}
{/*                  </div>*/}
{/*                </div>*/}
{/*              </div>*/}
{/*            )*/}
{/*          })}*/}
{/*        </div>*/}
{/*      </div>*/}
    </div>
  )
}

export default PricingIntroSection
