import React from "react"
import "../../styles/pricing/Pricing.css"
import PricingIntroSection from "./PricingSubComponent/PricingIntroSection"
import PricingFAQSection from "./PricingSubComponent/PricingFAQSection"
function Pricing() {
  return (
    <div>
     <PricingIntroSection/>
   <PricingFAQSection/>
    </div>
  )
}

export default Pricing
